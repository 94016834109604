<template>
  <div>
    <div class="demo-inline-spacing mb-2">
      <b-button v-b-modal.permission-modal variant="primary">
        Nouvelle demande
      </b-button>
    </div>

    <b-modal ref="my-modal" show="resetModal" id="permission-modal" centered modal-class="modal-primary"
      title="Demande de permission." hide-footer>
      <validation-observer ref="permissionForm">
        <b-form>
          <b-form-group>
            <ky-input v-model="permission.object" :placeholder="'Objet de permission'" :rules="'required'"
              :type="'text'" />
          </b-form-group>
          <div class="row">
            <div class="col-md-6">
              <b-form-group>
                <ky-input v-model="start_date" :placeholder="'Date de départ'" :rules="'required'" :type="'date'" />
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group>
                <ky-input v-model="start_time" :placeholder="'Heure de depart'" :rules="'required'"
                :type="'time'" />
              </b-form-group>
            </div>
          </div>
          
          <div class="row">
            <div class="col-md-6">
              <b-form-group>
                <ky-input v-model="end_date" :placeholder="'Date de d\'arrivée'" :rules="'required'" :type="'date'" />
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group>
                <ky-input v-model="end_time" :placeholder="'Heure d\'arrivée'" :rules="'required'"
                  :type="'time'" />
              </b-form-group>
            </div>
          </div>
          <b-form-group>
            <ky-input :isTextarea="true" placeholder="Message" name="description" v-model="permission.description"
              :rules="'required'" />
          </b-form-group>

          <b-button :disabled="loading" @click.prevent="requestPermissions">
            <b-spinner v-if="loading" small type="grow"></b-spinner> Ajouter
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>
    <!-- Second row -->
    <b-tabs>
      <b-tab>
        <template #title>
          <feather-icon icon="ActivityIcon" />
          <span>Permissions en instance</span>
        </template>

        <b-card-text>
          <b-card>
            <instance-permissions />
          </b-card>
        </b-card-text>
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="CheckCircleIcon" />
          <span>Permissions accordées</span>
        </template>

        <b-card-text>
          <b-card>
            <accord-permissions />
          </b-card>
        </b-card-text>
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="XSquareIcon" />
          <span>Permissions rejetées</span>
        </template>

        <b-card-text class="mt-2 BBtable">
          <b-card>
            <rejected-permissions />
          </b-card>
        </b-card-text>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import kyInput from "@/@core/layouts/components/ky-input.vue";
import { mapActions, mapState } from "vuex";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import { BProgress } from "bootstrap-vue";
import AccordPermissions from "./permissionTable/AccordPermissions.vue";
import InstancePermissions from "./permissionTable/InstancePermissions.vue";
import RejectedPermissions from "./permissionTable/RejectedPermissions.vue";
export default {
  components: {
    AccordPermissions,
    InstancePermissions,
    RejectedPermissions,
    kyInput,
    BProgress,
  },

  data() {
    return {
      loading: false,
      start_date: "",
      start_time: "",
      end_date: "",
      end_time: "",
    }
  },

  computed: {
    ...mapState("member", ["permissions", "permission"]),
    ...mapState("auth", ["userInfo"]),
  },

  mounted() {
    // Set the initial number of items
    this.totalRows = this.permissions.length;

    this.fetchPermissions(this.userInfo.id);
  },


  methods: {
    ...mapActions("member", ["requestPermission", "fetchPermissions", 'allPermissions']),
    ...mapActions("enterprise", ["fetchServices"]),

    async requestPermissions() {
      const valid = await this.$refs.permissionForm.validate();
      if (!valid) return;
      this.loading = true;

      this.permission.start_date = `${this.start_date} ${this.start_time}`;
      this.permission.end_date = `${this.end_date} ${this.end_time}`;
      this.requestPermission(this.$formData(this.permission))
        .then((r) => {
          this.allPermissions();
          this.loading = false;
          this.permission.object = "";
          this.permission.description = "";
          this.permission.start_time = "";
          this.permission.end_time = "";
          this.permission.date = "";
          this.resetModal = false;
          this.$refs["my-modal"].hide();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Demande de permission envoyée avec succès",
              icon: "EditIcon",
              variant: "success",
            },
          })
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Une erreur est survenue",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          this.loading = false;
          this.errorMessage = error.message;
        });
    },
  },
};
</script>


<style>
.add-story-card {
  width: 71%;
  position: absolute;
  bottom: 10px;
  background: white;
  height: 50px;
  margin-bottom: 5px;
}

.add-story-btn {
  margin-top: -24px;
  margin-left: 30px;
  border: 3px solid white;
}

.avatar {
  margin-left: 10px;
}

.employees-card {
  margin-right: 9% !important;
}

.Btable tr:hover {
  background-color: rgb(247, 247, 247);
  padding-bottom: 10%;
  margin-top: 10vh;
  border-bottom: 3px solid;
  border-color: rgb(245, 245, 245);
}

.BBtable {
  border: 2px solid;
  border-color: rgb(231, 231, 231);
}
</style>
<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>

