<template>
  <div>
    <b-card-body>
      <div class="d-flex justify-content-between flex-wrap">
        <!-- filter -->
        <b-form-group
          label-cols-sm="10"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0 ml-auto"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Rechercher..."
            />
            <b-input-group-append>
              <b-button @click="filter = ''" class="btn btn-secondary">
                Effacer
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </b-card-body>
    <b-table
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="admin_permissions"
      :fields="permissionItems"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
      :busy="loading"
    >
      <!-- <template #cell()>
      <span>{{ this.notExist }}</span>
    </template> -->

      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong> Chargement...</strong>
        </div>
      </template>

      <template #cell(actions)="row">
        <b-row>
          <b-col cols="auto">
            <button
              title="Voir les details"
              type="button"
              class="btn btn-icon ml-auto btn-primary btn-sm"
              style="position: relative"
              @click="info(row.item, row.index, $event.target)"
            >
              <feather-icon icon="CornerRightUpIcon" size="18" />
            </button>
          </b-col>
          <b-col cols="auto">
            <button
              :disabled="isProcessing"
              title="Accorder"
              type="button"
              class="btn btn-icon btn-outline-success btn-sm"
              @click="acceptRequest(row.item.id)"
            >
              <b-spinner v-if="isProcessing" small type="grow" />
              <feather-icon v-else icon="CheckCircleIcon" size="18" />
            </button>
          </b-col>
          <b-col cols="auto">
            <button
              :disabled="isProcessing"
              title="Rejeter"
              type="button"
              class="btn btn-icon btn-outline-danger btn-sm"
              style="position: relative"
              @click.prevent="rejectRequest(row.item.id)"
            >
              <b-spinner v-if="isProcessing" small type="grow" />
              <feather-icon v-else icon="XCircleIcon" size="18" />
            </button>
          </b-col>
        </b-row>
      </template>

      <template #cell(user)="data">
        {{ fullname(data.item) }}
      </template>
    </b-table>

    <b-modal
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :body-text-variant="bodyTextVariant"
      :footer-bg-variant="footerBgVariant"
      :id="infoModal.id"
      :title="infoModal.title"
      header-close-variant="danger"
      ok-only
      @hide="resetInfoModal"
    >
      <pre>{{ infoModal.content }}</pre>
    </b-modal>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        label="Affichage par page"
        label-cols="8"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </div>
</template>

<script>
import BCard from "bootstrap-vue";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import {
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
} from "bootstrap-vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    ToastificationContent,
    BCardBody,
  },
  data() {
    return {
      loading: false,
      isProcessing: false,
      notExist: "Aucune demande disponible",
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      variants: ["light", "dark"],
      headerBgVariant: "",
      headerTextVariant: "light",
      bodyBgVariant: "light",
      bodyTextVariant: "dark",
      footerBgVariant: "light",
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      permissionItems: [
        { key: "start_date", label: "Départ" },
        { key: "end_date", label: "Arrivée" },
        { key: "object", label: "Objet" },
        { key: "user", label: "Collaborateur", thStyle: { width: 0 } },
        { key: "actions", label: "Actions", thStyle: { width: "16rem" } },
      ],
      /* eslint-disable global-require */

      /* eslint-disable global-require */
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.permissionItems
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },

    ...mapState("member", ["admin_permissions", "granted_permission"]),
  },
  mounted() {
    this.totalRows = this.admin_permissions.length;
    this.fetchAllPermissions();
  },
  methods: {
    fullname(item) {
      return item?.user?.firstname + " " + item?.user?.lastname;
    },

    fetchAllPermissions() {
      this.loading = true;
      this.allPermissions()
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    info(item, index, button) {
      this.infoModal.title = `Details demande de permission:`;
      this.infoModal.content =
        "Objet : " +
        item.object +
        "\n------\nDescription : \n" +
        item.description;
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(permissionItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = permissionItems.length;
      this.currentPage = 1;
    },

    acceptRequest(data) {
      this.isProcessing = true;
      this.allowPermission(data)
        .then(() => {
          this.isProcessing = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Demande acceptée",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.fetchAllPermissions();
        })
        .catch((err) => {
          this.isProcessing = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Une erreur s'est produite",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },

    rejectRequest(data) {
      this.isProcessing = true;
      this.rejectPermission(data)
        .then(() => {
          this.isProcessing = false;
          this.fetchAllPermissions();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Demande refusée",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        })
        .catch((err) => {
          this.isProcessing = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Une erreur a été rencontrée",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },

    ...mapActions("member", [
      "allPermissions",
      "allowPermission",
      "rejectPermission",
    ]),
  },
};
</script>
