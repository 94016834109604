<template>
  <div>
 
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <!-- filter -->
          <b-form-group
            label-cols-sm="10"
            label-align-sm="left"
            label-size="sm"
            label-for="filterInput"
            class="mb-0 ml-auto"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Rechercher..."
              />
              <b-input-group-append>
                <b-button @click="filter = ''" class="btn btn-secondary">
                  Effacer
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-card-body>

      <b-table  
        table-variant="success"
        responsive
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="granted_permissions"
        :fields="allowedPermissionItems"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
      <template #cell(user)="data">
        {{ data.item.user.firstname +' '+ data.item.user.lastname }}
      </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Affichage par page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
 
  </div>
</template>

<script>
import BCard from "bootstrap-vue";
import {
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
} from "bootstrap-vue";
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
  },
  data() {
    return {
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      allowedPermissionItems: [
        { key: "start_date", label: "Départ" },
        { key: "end_date", label: "Arrivée" },
        {key: "object", label: "objet"},
        {key: "user", label: "collaborateur"},
      ],
      /* eslint-disable global-require */
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.allowedPermissionItems
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },

    ...mapState("member", ["granted_permissions", "granted_permission"]),
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.granted_permissions.length;
    this.allAllowedPermissions();
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(granted_permissions) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = granted_permissions.length;
      this.currentPage = 1;
    },

    ...mapActions("member", ["allAllowedPermissions"])
  },
};
</script>
